import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import RootImage from '../components/RootImage'

class Homepage extends React.Component {
  render() {
    const siteTitle = 'Armley Steps'

    return (
      <Layout>
        <Helmet title={siteTitle} />

        <section id="intro" className="main style2">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>A work-out, and well needed break from the world</h2>
              </header>
              <p>
                Although only a distance of 0.08km, these steps can provide a
                real sweat! This 161 step challenge pushes you to feel the burn.
              </p>
              <p>
                Usually a place where run groups visited on a Saturday, but
                during the week - a peaceful escape from the world.
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/armley-park/#introduction"
              >
                More about Armley Park
              </a>
            </div>
            <div className="col-6">
              <span className="image fit">
                <RootImage filename="pic01.jpg" alt="Armley Killer Steps" />
              </span>
            </div>
          </div>
        </section>

        <section id="location" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit">
                <RootImage filename="pic02.jpg" alt="Armley Killer Steps" />
              </span>
            </div>
            <div className="col-6">
              <header className="major">
                <h2>Location</h2>
              </header>
              <p>
                The steps rise up to the back of Armley park. Bordering with
                Gotts park, Armley park is two miles west of Leeds city centre
                and is approximately 14 hectares in area. It is the perfect
                location for people of all age ranges to enjoy a relaxing day
                out. The park gives local residents and visitors amazing views
                over Kirkstall Valley.
              </p>
              <p>
                The bottom of Armley Steps leads down to the Leeds Liverpool
                canal, and a gentle walk through a densely wooded area. Quiet
                woodland walks in the inner city.
              </p>
              <p>
                If you are looking to drive and park near Armley Steps, we
                recommend using the Gotts Park car park. The postcode is LS12
                2QX, then is is only a short walk to the steps.
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/armley-park"
              >
                Explore Armley Park
              </a>
            </div>
          </div>
        </section>

        <section id="why" className="main style2">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>Why Armley Steps?</h2>
              </header>
              <p>
                There are a number of health benefits to training on stairs as
                opposed to walking or running.
              </p>
              <ul>
                <li>Especially good for toning your bum, thighs and core.</li>
                <li>
                  An intense session will give more aerobic benefits. 30 minutes
                  of stair running will burn around 500 calories.
                </li>
                <li>
                  Burns twice the amount of fat than a normal run, and three
                  times that walking.
                </li>
                <li>It is low impact and safe for the knees.</li>
                <li>Helps improve bone strength.</li>
              </ul>
              <p>
                Due to the nature of the exercise, you do not need to travel
                far. If you need to call it a day, you are still at the stairs,
                where you started!
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/armley-park/#killer-steps"
              >
                More about Armley Killer Steps
              </a>
            </div>
            <div className="col-6">
              <span className="image fit">
                <RootImage
                  filename="pic03.jpg"
                  alt="Running up Armley Killer Steps"
                />
              </span>
            </div>
          </div>
        </section>

        <section id="history" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit">
                <RootImage filename="pic04.jpg" alt="Armley Mansion in 1820" />
              </span>
            </div>
            <div className="col-6">
              <header className="major">
                <h2>History of Gott's Park and Armley Steps</h2>
              </header>
              <p>
                Armley park is a simple mix of green trees, open space and
                beautiful architectural gems. However, it has quite a bit of
                history!
              </p>
              <a
                className="button special"
                href="https://discoverleeds.co.uk/things-to-see-and-do/outdoors/armley-park/#history"
              >
                History of Armley park
              </a>
              <br /> <br />
              <p>
                The area was originally owned by a merchant, Thomas Woolrich,
                and the grounds were sold to him by Thomas White in the late
                C18. Benjamin Gott, who the park is named after, was a
                prosperous cloth manufacturer. He bought the land in 1803 and
                lived there until his death in 1840.
              </p>
              <p>
                During Benjamin Gotts life, Humphry Repton was consulted in 1810
                and produced a Red Book, 'Armley House near Leeds in Yorkshire'.
                Some of the proposals made in this book were carried out. The
                land stayed in private ownership until 1928 when it was acquired
                by Leeds City Council. It is now a golf course and public park,
                with the house now used as the Golf Clubhouse.
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default Homepage
